import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import "./App.css";
import Loader from "./loader/Loader";
import Landing from "./landing/Landing";
import Signup_1 from "./signup/Signup_1";
import Signup_2 from "./signup/Signup_2";
import Signup_3 from "./signup/Signup_3";
import Dashboard from "./dashboard/Dashboard";

import Account from "./account/Account";
import Navbar from "./navbar/Navbar";
import Settings from "./gsettings/Settings";
import Preferences from "./Preferences/Preferences";
import Devices from "./devices/Devices";
import GeneralSettings from "./settingsoptions/GeneralSettings";
import PaymentInfo from "./settingsoptions/PaymentInfo";
import CreateAccount from "./settingsoptions/CreateAccount";
import AllDevices from "./devicesoptions/AllDevices";
import Signinoptions from "./devicesoptions/Signinoptions";
import Billing from "./billing/Billing";
import MyBilling from "./billingoptions/MyBilling";
import MyPlans from "./billingoptions/MyPlans";
import Home from "./home/Home";
import Utility from "./utility/Utility";
import UtilityHealthcare from "./utility-healthcare/UtilityHealthcare";
import UtilityFinance from "./utility-finance/UtilityFinance";
import Store from "./store/Store";
import Loader2 from "./loader2/Loader2";
import BottomSettings from "./gsettings/BottomSetting";
import GlobalLoading from "./globalLoading/GlobalLoading";
import Warning from "./warning/Warning";

function App() {
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    function handleResize() {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (screenSize.width < 1366 || screenSize.height < 768) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [screenSize]);
  const spinner = document.getElementById("preloader");
  if (spinner) {
    setTimeout(function () {
      // slideBox.style.display = "none"
      spinner.style.opacity = 1;
      (function fade() {
        (spinner.style.opacity -= 0.1) < 0
          ? (spinner.style.display = "none")
          : setTimeout(fade, 170);
      })();
    }, 3230);
  }

  function DashboardWrapper() {
    const [loading2, setLoading2] = useState(false);
    const location = useLocation();
    useEffect(() => {
      if (location.pathname === "/Dashboard") {
        setLoading2(true);
        setTimeout(() => {
          setLoading2(false);
        }, 800);
      }
    }, [location.pathname]);

    return <>{loading2 ? <Loader2 /> : <Dashboard />}</>;
  }

  return (
    <>
      <BrowserRouter>
      {/* { showWarning && <Warning /> } */}
        <Routes>
          {/* <Route path="/" element={<GlobalLoading />} /> */}
          {/* <Route path="/loader" element={<Loader />} /> */}
          {/* <Route path="/landing" element={<Landing />} />
          <Route path="/Signup-1" element={<Signup_1 />} />
          <Route path="/Signup-2" element={<Signup_2 />} />
          <Route path="/Signup-3" element={<Signup_3 />} /> */}
          <Route path="/Dashboard" element={<DashboardWrapper />} />
        </Routes>
        <Routes>
          <Route path="/" element={<Home />}></Route>

          <Route path="/Account" element={<Account />}></Route>
          {/* <Route path="/account" element={<Account />}></Route> */}
          {/* <Route path="/account" element={<SettingWrapper />} /> */}
          <Route path="/store" element={<Store />}></Route>

          <Route path="/Settings" element={<Settings />}>
            <Route index element={<GeneralSettings />} />
            <Route path="generalsettings" element={<GeneralSettings />} />
            <Route path="paymentinfo" element={<PaymentInfo />} />
            <Route path="account" element={<CreateAccount />} />
          </Route>

          <Route path="/Devices" element={<Devices />}>
            <Route index element={<AllDevices />} />
            <Route path="alldevices" element={<AllDevices />} />
            <Route path="devicessignin" element={<Signinoptions />} />
          </Route>
          <Route path="/Preferences" element={<Preferences />}></Route>
          <Route path="/Billing" element={<Billing />}>
            <Route index element={<MyBilling />} />
            <Route path="mybilling" element={<MyBilling />} />
            <Route path="myplans" element={<MyPlans />} />
          </Route>
          <Route path="/Utility" element={<Utility />}></Route>
          <Route path="/utility" element={<Utility />}></Route>
          <Route path="/Healthcare" element={<UtilityHealthcare />} />
          <Route path="/Finance" element={<UtilityFinance />} />
          <Route path="/bottomSetting" element={<BottomSettings />} />
        </Routes>
        <Navbar/>
      </BrowserRouter>
    </>
  );
}

export default App;
