import React from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './MyBilling.css'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
const Barchart = () => {
    const a =[]
    for(let i=7;i>=0;i--){
        a.push(i)
    }
    const current = new Date();
    const date = `${current.getDate()}}`;
    const date1=[]
    for(const num of a){
        date1.push(`${parseInt(date)-num}/${current.getMonth()+1}`)
    }
    

    var data= {
        labels: date1,
  datasets: [
      {
        label: 'Mobile',
        data:[7,7,8,6,3,9,9,3] ,
        backgroundColor: '#EA267A',
        // transform: 'rotate(90deg)',
        borderWidth: '8',
        borderRadius: '71.5px',
        borderSkipped: 'false',
      },
      {
        label: 'Web-app',
        data:[9,4,5,6,7,6,4,6] ,
        backgroundColor: '#EF4EEF',
        // transform: 'rotate(90deg)',
        borderWidth: '8',
        borderRadius: '72px',
        borderSkipped: 'false',
      },
    {
      label: 'Software',
      data:[5,6,8,9,9,2,9,8] ,
      backgroundColor: '#4339F2',
      // transform: 'rotate(90deg)',
      borderWidth: '8',
      borderRadius: '71.5px',
      borderSkipped: 'false',
    },
  ],
};
    const options = {
        plugins: {
          title: {
            display: true,
            
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };
    return (
        <>
        <div className="barchart">
            <Bar
            data={data}
            height={400}
            options={options}
            />
         </div>   
        </>
    )
}

export default Barchart