import React, { useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { FaChevronLeft, FaArrowRight, FaChevronRight } from "react-icons/fa";
import { myplansData } from "./MyPlansData";
import Rating from "./Rating";

const MyPlansCard = () => {
  const NextArrow = ({ onClick }) => {
    return <FaChevronRight className="arrow next" onClick={onClick} />;
  };
  const PrevArrow = ({ onClick }) => {
    return <FaChevronLeft className="arrow prev" onClick={onClick} />;
  };

  const [dataIndex, setDataIndex] = useState(0);
  const small = window.matchMedia("(max-width: 600px)");
  const settings = {
    isFinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 2,
    centerMode: true,
    centerPadding: 205,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setDataIndex(next),
  };

  if (small.matches) {
    settings.slidesToShow = 1;
  }

  
  return (
    <div className="container">
      <Slider className="slider" {...settings}>
        {myplansData.map((slide, index) => {
          return (
            <div
            // className="slide"
              className={index === dataIndex ? "slide activeSlide" : "slide"}
            >
              <div className="myplans-features">
                <h2 className="myplans-heading">{slide.heading}</h2>
                <p>{slide.info}</p>
                <div className={index === dataIndex ? "hello myplans-plans" : "myplans-plans"}
                // className="myplans-plans"
                >
                  <Rating />
                  {slide.plans}
                </div>
                <hr />
                <div className="myplans-info">
                  <table>
                    <tr>
                      <th className="t1"> {slide.row1}</th>
                      <th className="t2">{slide.row2}</th>
                      <th className="t3">{slide.row3}</th>
                    </tr>
                    <tr>
                      <td className="t4">{slide.row1Col1}</td>
                      <td>{slide.row1Col2}</td>
                      <td>{slide.row1Col3}</td>
                    </tr>
                    <tr>
                      <td className="t4">{slide.row2Col1}</td>
                      <td>{slide.row2Col2}</td>
                      <td>{slide.row2Col3}</td>
                    </tr>
                    <tr>
                      <td className="t4">{slide.row3Col1}</td>
                      <td>{slide.row3Col2}</td>
                      <td>{slide.row3Col3}</td>
                    </tr>
                    <tr>
                      <td className="t4">{slide.row4Col1}</td>
                      <td>{slide.row4Col2}</td>
                      <td>{slide.row4Col3}</td>
                    </tr>
                    <tr>
                      <td className="t4">{slide.row5Col1}</td>
                      <td>{slide.row5Col2}</td>
                      <td>{slide.row5Col3}</td>
                    </tr>
                  </table>
                </div>

                <div className="button">
                  <Link to="/getStarted">
                    <button className="myplan-button">{slide.button}</button>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}{" "}
      </Slider>
    </div>
  );
};

export default MyPlansCard;
