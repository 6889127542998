import React, { useEffect, useRef } from 'react';
import { Link, NavLink, Outlet } from 'react-router-dom';
import Backbutton from '../assets/images/left.png';
import Navbar from '../navbar/Navbar';
import './Settings.css';

const Settings = () => {
  const navbar = useRef(null);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      const btnContainer = navbar.current;
      const btns = btnContainer.getElementsByClassName('btn');
  
      for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener('click', function () {
          const current = document.getElementsByClassName('active');
          current[0].className = current[0].className.replace(' active');
  
          this.className += ' active';
        });
      }
      const generalSettingsBtn = navbar.current.querySelector('.general-settings-btn');
      generalSettingsBtn.addEventListener('click', () => {
        generalSettingsBtn.className += ' active';
      });
    });
  }, []);  

  return (
    <div className="settings">
       <div className='background'>
          <div className='purple'></div>
          <div className='blue'></div>
        </div>
      <div className="backbutton">
        <Link to="/account">
          <img src={Backbutton} alt="" />
        </Link>
      </div>
      <div className="settings-background">
        <div className="settings-content">
          <div className="settingNav" id="settingNav" ref={navbar}>
            <ul className="settings-navbar">
              <li>  
                <NavLink to="generalsettings" className="btn active general-settings-btn">
                  <button className="settings-item">General Settings</button>
                </NavLink>
              </li>
              <li>
                <NavLink to="paymentinfo" className="btn">
                  <button className="settings-item">Payment Info</button>
                </NavLink>
              </li>
              <li>
                <NavLink to="account" className="btn">
                  <button className="settings-item">Connect Account</button>
                </NavLink>
              </li>
            </ul>
          </div>
          <Outlet />
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default Settings;