import React, {useState} from 'react'
import homei from '../assets/images/Homei.svg'
import accounti from '../assets/images/Accounts.svg'
import shoppingi from '../assets/images/Shopings.svg'
import educationi from '../assets/images/Education.svg'
import identityi from '../assets/images/identity icon.svg'
import materiali from '../assets/images/Phonei.svg'
import settingsi from '../assets/images/Settingsi.svg'
import logouti from '../assets/images/logouti.png'
import utilityi from '../assets/images/Utilityi.svg'
import {  NavLink } from 'react-router-dom'
import './Navbar.css'
import { useEffect, useRef } from 'react'

const Navbar = () => {
    const navbar = useRef(null); 
    // const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        window.requestAnimationFrame(() => {
            const btnContainer = navbar.current;
            const btns = btnContainer.getElementsByClassName("btn");
            for (let i = 0; i < btns.length; i++) {
              btns[i].addEventListener("click", function () {
                const current = document.getElementsByClassName("active");
                current[0].className = current[0].className.replace(" active");
                if (this.classList.contains("home-btn")) {
                  } else {
                    this.className += " active";
                    document.querySelector(".home-btn").classList.remove("active"); 
                  }
              });
            }
          });
        }, []);
        
      
    return (
        <div className='container-fluid'>
            <div className='navbar' id='navbar'>
                <ul className='navbar-list' ref={navbar}> 
                    <li className='navbar-items'><NavLink className="btn home-btn "  to='/Dashboard'><img src={homei} alt="" /></NavLink></li>
                    <li className='navbar-items' ><NavLink className="btn"  to='/account'><img src={accounti} alt="" /></NavLink></li>
                    <li className='navbar-items'><NavLink className="btn" to="/store"><img src={shoppingi} alt="" /></NavLink></li>
                    <li className='navbar-items'><img src={educationi} alt="" /></li>
                    <li className='navbar-items'><img src={identityi} alt='identityi' /></li>
                    <li className='navbar-items'><img src={materiali} alt="" /></li>
                    <li className='navbar-items'><NavLink className="btn" to='/Utility'><img src={utilityi} alt="" /></NavLink></li>
                    <li className='navbar-items'><img src={settingsi} alt="" /></li>
                </ul>
                <div className='navbar-items'><img src={logouti} alt='logouti' /></div>
            </div>
           
        </div>
    )
}

export default Navbar
