import React from 'react'
import './Loader2.css'
const Loader2 = () => {
  return (
    <div className="loaderglobal">
        <div className="loader10">
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
            <div className="loader-square"></div>
        </div>
    </div>
  )
}

export default Loader2
