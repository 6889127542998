 export const myplansData = [
{
    heading : 'Basic Tier',
    info : 'Our most papular plan Lorem Ipsum',
    plans : 'Price/month',
    row1 : 'Feature Set',
    row2 : 'Duration',
    row3 : 'Members',
    row1Col1 : 'Lorem Ipsum',row1Col2 : 'Lorem Ipsum',row1Col3 : 'Lorem Ipsum',
    row2Col1 : 'Lorem Ipsum',row2Col2 : 'Lorem Ipsum',row2Col3 : 'Lorem Ipsum',
    row3Col1 : 'Lorem Ipsum',row3Col2 : 'Lorem Ipsum',row3Col3 : 'Lorem Ipsum',
    row4Col1 : 'Lorem Ipsum',row4Col2 : 'Lorem Ipsum',row4Col3 : 'Lorem Ipsum',
    row5Col1 : 'Lorem Ipsum',row5Col2 : 'Lorem Ipsum',row5Col3 : 'Lorem Ipsum',
    button : 'Get Started'
},
{
    heading : 'Student Tier',
    info : 'Our most papular plan Lorem Ipsum',
    plans : 'Price/month',
    row1 : 'Feature Set',
    row2 : 'Duration',
    row3 : 'Members',
    row1Col1 : 'Lorem Ipsum',row1Col2 : 'Lorem Ipsum',row1Col3 : 'Lorem Ipsum',
    row2Col1 : 'Lorem Ipsum',row2Col2 : 'Lorem Ipsum',row2Col3 : 'Lorem Ipsum',
    row3Col1 : 'Lorem Ipsum',row3Col2 : 'Lorem Ipsum',row3Col3 : 'Lorem Ipsum',
    row4Col1 : 'Lorem Ipsum',row4Col2 : 'Lorem Ipsum',row4Col3 : 'Lorem Ipsum',
    row5Col1 : 'Lorem Ipsum',row5Col2 : 'Lorem Ipsum',row5Col3 : 'Lorem Ipsum',
    button : 'Get Started'
},

{
    heading : 'Basic Tier',
    info : 'Our most papular plan Lorem Ipsum',
    plans : 'Price/month',
    row1 : 'Feature Set',
    row2 : 'Duration',
    row3 : 'Members',
    row1Col1 : 'Lorem Ipsum',row1Col2 : 'Lorem Ipsum',row1Col3 : 'Lorem Ipsum',
    row2Col1 : 'Lorem Ipsum',row2Col2 : 'Lorem Ipsum',row2Col3 : 'Lorem Ipsum',
    row3Col1 : 'Lorem Ipsum',row3Col2 : 'Lorem Ipsum',row3Col3 : 'Lorem Ipsum',
    row4Col1 : 'Lorem Ipsum',row4Col2 : 'Lorem Ipsum',row4Col3 : 'Lorem Ipsum',
    row5Col1 : 'Lorem Ipsum',row5Col2 : 'Lorem Ipsum',row5Col3 : 'Lorem Ipsum',
    button : 'Get Started'
},

]

