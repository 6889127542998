import React from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const LineChart=()=> {
    const a =[]
    for(let i=7;i>=0;i--){
        a.push(i)
    }
    const current = new Date();
    const date = `${current.getDate()}}`;
    const date1=[]
    for(const num of a){
        date1.push(`${parseInt(date)-num}/${current.getMonth()+1}`)
    }
    const data = {
        labels: date1,
        datasets: [
            {
            label: 'Account-1',
            data: [2,3,4,3,5,6,7,8],
              borderColor: '#34B53A',
              backgroundColor: '#34B53A',
              
              lineTension: '0.5',  
            },
            {
            label: 'Account-2',
            data: [3,7,9,2,6,8,8,6],
              borderColor: '#FFB200',
              backgroundColor: '#FFB200',
              lineTension: '0.5',  
              
            },
            {
            label: 'Account-3',
            data: [10,5,5,5,6,3,2,5],
              borderColor: '#FF3A29',
              backgroundColor: '#FF3A29',
              lineTension: '0.5',  
              
            },
            {
            label: 'Account-3',
            data: [10,8,6,4,6,7,5,9],
              borderColor: '#4339F2',
              backgroundColor: '#4339F2',
              lineTension: '0.5',  
              
            }],
      };
      const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
            bezierCurve: 'true'
          },
          title: {
            display: true,
          },
        },
      };
  return (
    <div>
        <div className="linechart">
        <Line
            data={data}
            options={options}
            
        />
    </div>
    </div>
  )
}

export default LineChart