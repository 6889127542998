import React from 'react'
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import './UtilityFinance.css'
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
const BarChart=()=> {
    var data= {
        labels: [1,2,3,4,5,6],
  datasets: [
      {
        label: 'Active',
        data:[2,5,4,5,3,5] ,
        backgroundColor: '#5A6ACF',
        borderWidth: '2',
        borderRadius: '71.5px',
  


      },
      {
        label: 'Passive',
        data:[3,4,3,4,5,4] ,
        backgroundColor: '#D8D9DB',
        borderWidth: '2',
        borderRadius: '72px',

      },
  ],
};
    const options = {
        plugins: {
          title: {
            display: null,
            
          },
          legend: {
            display: null
          }
        },
        responsive: true,
        scales: {
            x: {
                
            },
            y: {
              display:false,
              
              
            },
          },
      };
  return (
    <div>
        <div>
            <Bar
            data={data}
            options={options}
            height={250}
            width={440}
          
            />
         </div>   
    </div>
  )
}

export default BarChart