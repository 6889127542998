import React ,{useState}from 'react'
import  Slider  from 'react-slick'
import { FaChevronLeft,FaChevronRight, } from 'react-icons/fa'




const   AppointmentMonths = () => {
    const months = [
        {day: '01'},{day:'02'},{day:'03'},{day:'04'},{day:'05'},{day:'06'},{day:'07'},{day:'08'},{day:'09'},{day:'10'},{day:'11'},{day:'12'},{day:'13'},{day:'14'},{day:'15'},{day:'16'},{day:'17'},{day:'18'},{day:'20'},{day:'21'},{day:'22'},{day:'23'},{day:'24'},{day:'25'},{day:'26'},{day:'27'},{day:'28'},{day:'29'},{day:'30'},{day:'31'}]

    const NextArrow = ({onClick}) => {
        return (
          
          <FaChevronRight className="arrow next" onClick={onClick}/>
          
        )
      }
      const PrevArrow = ({onClick}) => {
        return (
          <FaChevronLeft className='arrow prev' onClick={onClick}/>
          
        )
      }

      const [dataIndex, setDataIndex] = useState(0)
  const small = window.matchMedia("(width:100%)");
  const settings = {
    isFinite: true,
    lazyLoad: true,
    speed: 50,
    slidesToShow:6,
    centerMode: true,
    centerPadding:5,
    nextArrow : <NextArrow/>,
    prevArrow : <PrevArrow/>,
    beforeChange: (current, next) => setDataIndex(next)
  }

  if (small.matches){
    settings.slidesToShow = 1
  }
  return (
    <div className='date'>
      <Slider {...settings}>
      {months.map((slide,index) => {
        return (
            <div className={index === dataIndex ? "slide activeSlide" : "slide"}>
              <button className='date-button'>{slide.day}</button>
            </div>
        )
      })}
      </Slider>
    </div>
  )
}

export default AppointmentMonths
