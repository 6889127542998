import React from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Legend, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale, //x
  LinearScale, //y
  PointElement,
  LineElement,
  Legend,
  Tooltip
);



const LineChart = () => {



// tooltipLine block 
const tooltipLine =
{
  id: 'tooltipLine',
  beforeDraw: chart => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx;
      ctx.save();
      const activePoint = chart.tooltip._active[0];

      ctx.beginPath();
      ctx.setLineDash([5, 7]);
      ctx.moveTo(activePoint.element.x, chart.chartArea.top);
      ctx.lineTo(activePoint.element.x, activePoint.element.y);
      ctx.linewidth = 2;
      ctx.strokeStyle = 'white';
      ctx.stroke();
      ctx.restore();


      ctx.beginPath();
      ctx.setLineDash([5, 7]);
      ctx.moveTo(activePoint.element.x, activePoint.element.y);
      ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
      ctx.linewidth = 2;
      ctx.strokeStyle = 'white';
      ctx.stroke();
      ctx.restore();
    }

  }


}



  const data = {
    labels: [" ", "", "", "", "", "", "", ""],
    datasets: [{
      label: 'calories',
      data: [0, 25, 15, 42, 22, 24, 5, 39], //y-value
      backgroundColor: 'aqua',
      pointBorderColor: 'aqua',
      pointBorderWidth: 1,
      borderColor: 'aqua',
      fill: true,
      tension: 0.4,
      hoverBorderColor: '#9747FF',
      pointHoverRadius: 1,
      pointHoverBackgroundColor: '#9747FF'
      
    },

    {
      label: 'Weight',
      borderDash: [8, 2],
      data: [0, 20, 25, 15, 44, 5, 42, 43],
      backgroundColor: 'red',
      border: false,
      pointBorderColor: 'red',
      pointBorderWidth: 0,
      color: 'white',
      borderColor: 'red',
      fill: true,
      tension: 0.4,
      hoverBorderColor: '#9747FF',
      pointHoverRadius: 1,
      pointHoverBackgroundColor: '#9747FF'

    },
    {
      label: 'Blood Sugar',
      borderDash: [8, 2],
      data: [20, 18, 4, 22, 25, 18, 41, 30, 34, 29],
      backgroundColor: '#9747FF',
      pointBorderColor: '#9747FF',
      pointBorderWidth: 1,
      borderColor: '#9747FF',
      fill: true,
      tension: 0.4,
      hoverBorderColor: '#3489eb',
      pointHoverRadius: 1,
      pointHoverBackgroundColor: '#3489eb'

    },
    ]
  };

  const options = {
    
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 6
      }
    },
    hover: {
      mode: 'dataset',
      intersect: false,
      borderSolid: true

    },
  
      scales: {
        y: {
          ticks: {
            min: 0,
            max: 60,
            //  step size 
            stepSize: 20 
          }
        }
      },
      Plugins:[tooltipLine]

    
    
  }


  
  return (
    <div>

      <div className="chart">
        Statistics <section className="wrapper">
          <select className='week'>
            <option value="" selected>Weekly</option>
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
          </select>
        </section>
        <Line data={data} options={options} 
        ></Line>
      </div>
    </div>
  )
}

export default LineChart
