import React, { useState, useEffect } from "react";
import "./Home.css";
import CardIMG from "../assets/images/HomeCardImg.jpg";
import Edulogo from "../assets/images/cil_education.png";
import Home_BannerIMG from "../assets/images/Frame105.png";
import {BsSearch} from "react-icons/bs"
import landingtemp from "../assets/images/landingtemp.png";
import rightarrow from "../assets/images/righti.png";
import storecard from "../assets/images/storecard.png";
import recent1img from "../assets/images/Recent-1.png";
import recent2img from "../assets/images/Recent-3.png";
import Ratings2 from "./Ratings2";
import BottomSetting from "../gsettings/BottomSetting";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import favicon from '../assets/images/Favicon.png'
// import ellipses from '../assets/images/Vector.png'

const Home = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <div className='landing'>
      {/* ======NavBar====== */}
      <div className='landingNavBar'>
        <div className='RightNavBar'>
          {/* SearchBar */}
          <div className='SearchBar'>
            <div className="Inner_SearchArea">
            <BsSearch className="SearchIcon"/>
            <input type="text" className="inputText" placeholder="SEARCH"/>

            </div>
          </div>
        </div>
        <div className='LeftNavBar'>
          {/* Notification */}
          <div className='Notification'></div>
          {/* Profile */}
          <div className='Profile'></div>
        </div>
      </div>
      {/* =============== Home Container ================= */}

      <div className='MainHomeContainer'>
        <div className='InnerHomeContainer'>
            {/* ========== lEFT SIDE =============  */}
          <div className='LeftHomeContainer'>
            <div className='InnerLeftHomeContainer'>
              {/* Top Home Banner */}
              <div className='Home_Banner'>
                <img src={Home_BannerIMG} alt="" />
              </div>
              {/* QUICK ACCESSS */}
              <div className='Quickaccess'>
                {/* card */}
                <div className='Edu_Card QuickCard'>
                  <div className='TextQuickCard'>
                    <h2>Education</h2>
                  </div>
                </div>
                {/* card */}
                <div className='Edu_Card QuickCard'>
                  <div className='TextQuickCard'>
                    <h2>Dailyuse</h2>
                  </div>
                </div>
                {/* card */}
                <div className='Edu_Card QuickCard'>
                  <div className='TextQuickCard'>
                    <h2>Finance</h2>
                  </div>
                </div>
                {/* card */}
                <div className='Edu_Card QuickCard'>
                  <div className='TextQuickCard'>
                    <h2>Computing</h2>
                  </div>
                </div>
              </div>
              {/* Home Store */}
              <div className='Home_Store'>
                <h1>STORE</h1>
                <div className='Home_StoreCard'>
                  <div className='Card'>
                    <div className='ImageCard'>
                      <img src={CardIMG} alt='' />
                    </div>
                    <div className='ContentCard'>
                      <div className='TextContentCard'>
                        {/* Card Heading */}
                        <h3>XGC GOSSIPS PACK</h3>
                        {/* Its FREE / PRICE */}
                        <h4>FREE</h4>
                        {/* NUMEBR OF DOWNLAODs */}
                        <p>Total Downloads: 12.15k</p>
                        {/* BUTTON TO RENDER THE MAIN PAGE */}
                        <div className='DownloadBTn_StoreCard'>D</div>
                      </div>
                    </div>
                  </div>
                  {/* FOR MORE CARD */}
                  <div className='Card'>
                    <div className='ImageCard'>
                      <img src={CardIMG} alt='' />
                    </div>
                    <div className='ContentCard'>
                      <div className='TextContentCard'>
                        {/* Card Heading */}
                        <h3>XGC GOSSIPS PACK</h3>
                        {/* Its FREE / PRICE */}
                        <h4>FREE</h4>
                        {/* NUMEBR OF DOWNLAODs */}
                        <p>Total Downloads: 12.15k</p>
                        {/* BUTTON TO RENDER THE MAIN PAGE */}
                        <div className='DownloadBTn_StoreCard'>D</div>
                      </div>
                    </div>
                  </div>
                  <div className='Card'>
                    <div className='ImageCard'>
                      <img src={CardIMG} alt='' />
                    </div>
                    <div className='ContentCard'>
                      <div className='TextContentCard'>
                        {/* Card Heading */}
                        <h3>XGC GOSSIPS PACK</h3>
                        {/* Its FREE / PRICE */}
                        <h4>FREE</h4>
                        {/* NUMEBR OF DOWNLAODs */}
                        <p>Total Downloads: 12.15k</p>
                        {/* BUTTON TO RENDER THE MAIN PAGE */}
                        <div className='DownloadBTn_StoreCard'>D</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Home Recently Viewed */}
              <div className='Home_RecentlyViewed'>
                <h1>RECENTLY VIEWED</h1>
                <div className='Home_StoreCard'>
                {/* card */}
                <div className='RcentCard'>
                  <div className='logoRcentCard'>
                    <img src={Edulogo} alt='' />
                  </div>
                  <div className='RcentContentCard'>
                    <div className='RcentTextContentCard'>
                      {/* Card Heading */}
                      <h3>XGC GOSSIPS PACK</h3>
                      {/* Its FREE / PRICE */}
                      <h4>FREE</h4>
                      {/* NUMEBR OF DOWNLAODs */}
                      <p>Total Downloads: 12.15k</p>
                      {/* BUTTON TO RENDER THE MAIN PAGE */}
                    </div>
                  </div>
                </div>
                <div className='RcentCard'>
                  <div className='logoRcentCard'>
                    <img src={Edulogo} alt='' />
                  </div>
                  <div className='RcentContentCard'>
                    <div className='RcentTextContentCard'>
                      {/* Card Heading */}
                      <h3>XGC GOSSIPS PACK</h3>
                      {/* Its FREE / PRICE */}
                      <h4>FREE</h4>
                      {/* NUMEBR OF DOWNLAODs */}
                      <p>Total Downloads: 12.15k</p>
                      {/* BUTTON TO RENDER THE MAIN PAGE */}
                    </div>
                  </div>
                </div><div className='RcentCard'>
                  <div className='logoRcentCard'>
                    <img src={Edulogo} alt='' />
                  </div>
                  <div className='RcentContentCard'>
                    <div className='RcentTextContentCard'>
                      {/* Card Heading */}
                      <h3>XGC GOSSIPS PACK</h3>
                      {/* Its FREE / PRICE */}
                      <h4>FREE</h4>
                      {/* NUMEBR OF DOWNLAODs */}
                      <p>Total Downloads: 12.15k</p>
                      {/* BUTTON TO RENDER THE MAIN PAGE */}
                    </div>
                  </div>
                </div>
                
                {/*  */}
              </div>
              </div>

              <div className='Home_Banner'>
                <img src={Home_BannerIMG} alt="" />
              </div>
            </div>
          </div>
        {/* ========== RIGHT SIDE =============  */}

          <div className='RightHomeContainer'></div>
        </div>
      </div>
      
    </div>
  );
};

export default Home;
