import React from 'react'
import GeneralPreferences from '../preferencesoptions/GeneralPreferences'
import { NavLink } from 'react-router-dom'
import Backbutton from '../assets/images/left.png'
import Navbar from "../navbar/Navbar";
import './Preferences.css'

const Preferences = () => {
  return (
    
    <div className='preferences'>
       <div className='background'>
          <div className='purple'></div>
          <div className='blue'></div> 
        </div> 
            <div className='backbutton'> <NavLink to= '/account'> <img  src={Backbutton} alt="" /></NavLink></div>
      <div className='settings-background'>
        <div className='settings-content'>
          <div className='settings-nav'>
          <ul className='settings-navbar'>
            <li className='settings-navbar-item'><button className='settings-item'>General Preferences</button></li>
            <li className='settings-navbar-item'><button className='settings-item'>AI Preferences</button></li>
            <li className='settings-navbar-item'><button className='settings-item'>Utility</button></li>
          </ul>
          </div>
         <GeneralPreferences/>
        </div>
      </div>
      <Navbar/>
    </div>
  )
}

export default Preferences