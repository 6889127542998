import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnetChart = () => {
    var data={
        labels: ['Rent 40%','Food 32%','Medical 28%'],
  datasets: [
    {
      data: [40,32,28],
      backgroundColor: [
        'rgba(182, 59, 182, 1)',
         'rgba(234, 38, 122, 0.7)',
         'rgba(73, 0, 93, 1)'
      ],
      borderColor: [
        'rgba(182, 59, 182, 1)',
         'rgba(234, 38, 122, 0.7)',
         'rgba(73, 0, 93, 1)'
      ],
    },
  ],
};
const options = {
    responsive: true,
    plugins: {
      legend: {
            display: true
          
      },
    },
  };
  return (
    <div>
        <Doughnut
             data={data} 
             options={options}
            width={2000}
            height={2000}
            
        />
    </div>
  )
}

export default DoughnetChart
