import React from 'react'
import Barchart from './Barchart'
import LineChart from './LineChart'
import './MyBilling.css'
const MyBilling = () => {

  return (
    <div className='Billing'>
      <div className="Billing-left">
        <div className="chart1">
          <span className='Account-usage'>Account Usage</span>
          {/* <LineChart /> */}

        </div>

        <div className="chart2">
          <p className='Account-usage'>Device Usage</p>
          {/* <Barchart className='Barchart' /> */}
        </div>

        <div className="paymentHistory" >
          Payment History
        </div>
        <div className="paymentHistory-Navbar">
          <div >Date</div>
          <div >Details</div>
          <div>Amount</div>
          <div>Download</div>
        </div>
        <div className="paymentHistory-Details">
          <table>
            <tbody>
              <tr className='paymentHistory-table'>
                <td className='date'>9/11/22</td>
                <td className='Details'>Beginner plan,monthly</td>
                <td className='amount'>Lorem, ipsum.</td>
                <td className='download'>invoice 9 November</td>
              </tr>
              <tr className='paymentHistory-table'>
                <td className='date'>8/10/22</td>
                <td className='Details'>Beginner plan,monthly</td>
                <td className='amount'>Lorem, ipsum.</td>
                <td className='download'>invoice 8 october</td>
              </tr>
              <tr className='paymentHistory-table'>
                <td className='date'>9/11/22</td>
                <td className='Details'>Beginner plan,monthly</td>
                <td className='amount'>Lorem, ipsum.</td>
                <td className='download'>invoice 9 september</td>
              </tr>
        
            </tbody>
          </table>
        </div>
      </div>
      <div className="Billing-right">
        <div className="student-Tier">
          <h5 className='tier'>Student Tier</h5>
          <div className="paragrapg">
            Lorem ipsum dolor sit amet consectetur adipisicing elit.
          </div>
          <div className="footer">
            Your subscription ends in 10 days. Recharge now to avoid deactivation of account
          </div>
        </div>
        <button className='payment'>
          <h2>Make Payment</h2>
          {/* <h2 className="Make-payment" style={{cursor:'pointer'}}>Make Payment</h2> */}
          
        </button>
        <div className="add-method">
          
          <p className="Payment-method" style={{ cursor: "pointer" }} ><i className="fa-thin fa-plus"></i> Add Payment Method</p>
        </div>
        <div className="BrowsePlan">
          <h3 className='Browse'>Browse Plan <button className="fa-thin fa-greater-than" style={{ cursor: "pointer" }}></button></h3>
          <p className='para'>Search from the plan that best suit you</p>
        </div>
        <div className="cross">
          <button class="fa-regular fa-circle-xmark" style={{ cursor: "pointer" }}></button>
        </div>
      </div>
    </div>

  )
}

export default MyBilling