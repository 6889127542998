import React from 'react'

const Signinoptions = () => {
    return (
        <div className='signinoptions'>
            <div className='invitation'>
                <div className='from-invitation'>
                    <div className='content-title'>You have got invitation to connect account from :<span className='invitation-name' style={{ color: '#EA267A' }}> Meeta</span></div>
                    <div className='content-subtitle'>Meeta has sent you connection request on</div>
                </div>
                <div className='accept-reject'>
                    <div className='reject'>
                        <button className='reject-btn'>Decline</button>
                    </div>
                    <div className='accept'>
                        <button className='accept-btn'>Accept</button>
                    </div>

                </div>
            </div>

            <div className='invitation'>
                <div className='from-invitation'>
                    <div className='content-title'>You have got invitation to connect account from :<span className='invitation-name' style={{ color: '#EA267A' }}> Meeta</span></div>
                    <div className='content-subtitle'>Meeta has sent you connection request on</div>
                </div>
                <div className='accept-reject'>
                    <div className='reject'>
                        <button className='reject-btn'>Decline</button>
                    </div>
                    <div className='accept'>
                        <button className='accept-btn'>Accept</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Signinoptions