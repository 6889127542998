import React, { useState } from "react";
import Backbutton from "../assets/images/left.png";
import { NavLink } from "react-router-dom";
import BarChart from "./BarChart";
import DoughnetChart from "./DoughnetChart";
import LineChart from "./LineChart";
import Plus from "./Icons/plus.png";
import Upper from "./Icons/Upper.png";
import Lower from "./Icons/lower.png";
import Trip from "./Icons/trip.png";
import House from "./Icons/house.png";
import news from "./Icons/news-icon.png";
import Miscellenious from "./Icons/Medical.png";
import subscription from "./Icons/subscription.png";
import Food from "./Icons/Food.png";
import "./UtilityFinance.css";
import Navbar from "../navbar/Navbar";

const UtilityFinance = () => {
  const [stateInvestment, setstateInvestment] = useState(true);
  const [stateAsset, setstateAsset] = useState(true);
  const [stateLiabilites, setstateLiabilites] = useState(true);

  const DropdownInvestment = () => {
    setstateInvestment(!stateInvestment);
  };
  const DropdownAssets = () => {
    setstateAsset(!stateAsset);
  };
  const DropdownLiabilites = () => {
    setstateLiabilites(!stateLiabilites);
  };

  return (
    <>
      <div className='Finance'>
        <div className='background'>
          <div className='purple'></div>
          <div className='blue'></div>
        </div>
        <span className='backbutton1'>
          {" "}
          <NavLink to='/utility'>
            {" "}
            <img src={Backbutton} alt='' />
          </NavLink>
        </span>

        {/* Column 1 */}
        <div className='Finance_container'>
          <div className='Finance-left'>
            <div className='Finance-left_INNER'>
              <div className='Investment'>
                <div
                  className='Head_Investment'
                  onClick={() => setstateInvestment(!stateInvestment)}
                >
                  <span className='Investment-heading'>Investment</span>
                  <button className='Investment-Button'>
                    <img src={stateInvestment ? Upper : Lower} alt='upper' />
                  </button>
                </div>
                {stateInvestment ? (
                  <div className='Investment-Dropdown'>
                    <span className='Fixed-Deposit'>Fixed Deposit</span>
                    <div className='fixed-dposit-bar'>
                      <div className='fixed-dposit-bar-progress'></div>
                    </div>
                    <span className='Shares'>Shares</span>
                    <div className='Shares-bar'>
                      <div className='Shares-bar-progress'></div>
                    </div>
                    <span className='Real-State'>Real-State</span>
                    <div className='Real-State-bar'>
                      <div className='Real-State-bar-progress'></div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='assets'>
                <div
                  className='Head_assets'
                  onClick={() => setstateAsset(!stateAsset)}
                >
                  <span className='assets-Heading'>Assets</span>
                  <button className='assets-button'>
                    <img src={stateAsset ? Upper : Lower} alt='Upper' />
                  </button>
                </div>
                {stateAsset ? (
                  <div className='assets-Drop-down'>
                    <span className='asset1'>Lorem, ipsum.</span>
                    <div className='asset1-bar'>
                      <div className='asset1-bar-progress'></div>
                    </div>
                    <span className='asset2'>Lorem, ipsum.</span>
                    <div className='asset2-bar'>
                      <div className='asset2-bar-progress'></div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='liabilites'>
                <div
                  className='Head_liabilites'
                  onClick={() => setstateLiabilites(!stateLiabilites)}
                >
                  <span className='liabilites-Heading'>Liabilites</span>
                  <button className='liabilites-button'>
                    <img src={stateLiabilites ? Upper : Lower} alt='Upper' />
                  </button>
                </div>
                {stateLiabilites ? (
                  <div className='Liabilites-Dropdown'>
                    <span className='liabilites1'>Lorem, ipsum.</span>
                    <div className='liabilites1-bar'>
                      <div className='liabilites1-bar-progress'></div>
                    </div>
                    <span className='liabilites2'>Lorem, ipsum.</span>
                    <div className='liabilites2-bar'>
                      <div className='liabilites2-bar-progress'></div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/*  */}
          <div className='Finance-right'>
            {/* Row 1 */}
            <div className='quick_finance_details'>
              <div className='Networth'>
                <div className='networth'>Networth</div>
                <div className='networth-amount'>231,061K</div>
              </div>
              <div className='Assets'>
                <div className='Asset'>Assets</div>
                <div className='Asset-amount'>280,145K</div>
              </div>
              <div className='Liabilites'>
                <div className='Liabilite'>Liabilites</div>
                <div className='Liabilite-amount'>64,280K</div>
              </div>
              <div className='Credit-Score'>
                <div className='creditScore'>Credit Score</div>
                <div className='credit-score-point'>450</div>
              </div>
            </div>
            <div className='Finance_row'>
              <div className='Finance-right-first'>
                <div className='Income'>
                  <div className='income-header'>
                    <div> Income</div>
                    <button button className='income-details'>
                      View Details
                    </button>
                  </div>
                  {/* <div className="income-info">For Fy 2021-22
                <div className="income-comparing">2.1% vs last week</div>
                </div> */}
                  <div className='Income-graph'>
                    <BarChart />
                  </div>
                </div>
                <div className='Budgeting'>
                  <div className='Budgeting-Header'>Budgeting</div>
                  <div className="inner_Budgeting">
                    
                  <div className='Budgeting-bar'>
                    <div className='Budgeting-bar-progress'></div>
                  </div>
                  <div className='budgeting-footer'>
                    12,000Rs spend from 30,000Rs
                  </div>
                  {/* <<<< ======  ITEMS ====== >>>> */}
                  <div className='Budget_Item'>
                    <div className="inner_Budget_Item">

                    <div className='Budget_Item-img'>
                      <img src={subscription} alt='subs' />
                    </div>
                    <div className='Budget_Item-description'>
                      Subscription
                      <div className='Budget_Item-date'>20-Apr-2022</div>
                    </div>
                    <div
                      className='Budget_Item-cost'
                      id='food'
                      style={{ color: " rgba(251, 69, 83, 1)" }}
                    >
                      {"-500 Rs"}
                    </div>
                    </div>
                  </div>

                  <div className='Budget_Item'>
                    <div className="inner_Budget_Item">

                    <div className='Budget_Item-img'>
                      <img src={subscription} alt='subs' />
                    </div>
                    <div className='Budget_Item-description'>
                      Subscription
                      <div className='Budget_Item-date'>20-Apr-2022</div>
                    </div>
                    <div
                      className='Budget_Item-cost'
                      id='food'
                      style={{ color: " rgba(251, 69, 83, 1)" }}
                    >
                      {"-500 Rs"}
                    </div>
                    </div>
                  </div>

                  <div className='Budget_Item'>
                    <div className="inner_Budget_Item">

                    <div className='Budget_Item-img'>
                      <img src={subscription} alt='subs' />
                    </div>
                    <div className='Budget_Item-description'>
                      Subscription
                      <div className='Budget_Item-date'>20-Apr-2022</div>
                    </div>
                    <div
                      className='Budget_Item-cost'
                      id='food'
                      style={{ color: " rgba(251, 69, 83, 1)" }}
                    >
                      {"-500 Rs"}
                    </div>
                    </div>
                  </div>

                  <div className='Budget_Item'>
                    <div className="inner_Budget_Item">

                    <div className='Budget_Item-img'>
                      <img src={subscription} alt='subs' />
                    </div>
                    <div className='Budget_Item-description'>
                      Subscription
                      <div className='Budget_Item-date'>20-Apr-2022</div>
                    </div>
                    <div
                      className='Budget_Item-cost'
                      id='food'
                      style={{ color: "rgb(96, 255, 52)" }}
                    >
                      {"+800 Rs"}
                    </div>
                    </div>
                  </div>
                  
                  </div>
                </div>
              </div>

              <div className='Finance-Right-second'>
                <div className='Expenses'>
                  <span className='Expenses-header'>Expenses</span>
                  {/* <div className='DoughnutChart'>
                  <DoughnetChart />
                </div> */}
                </div>
                <div className='spending-Limits'>
                  <div className='spending-Limits-header'>Spending Limits</div>
                  <div className='transaction-Limit'>
                    Monthly Transaction Limit
                  </div>
                  <div className='spending-limits-bar'>
                    <div className='spending-limits-bar-progress'></div>
                  </div>
                  <div className='spending-Limits-footer'>
                    30,000Rs spent of 1,00,000Rs
                  </div>
                </div>
                <div className='Savings'>
                  <div className='savings-Header'>
                    Savings
                    <button className='saving-Details'>View Details</button>
                  </div>
                  <div className='savings-info'>For FY 2021-22</div>
                  <div className='linechart'>
                    <LineChart />
                  </div>
                </div>
              </div>
              <div className='Finance-Right-third'>
                <div className='Goals'>
                  Goals
                  <button className='goals-sign'>
                    <img src={Plus} alt='plus' />
                  </button>
                </div>
                <div className='Holiday-Trip'>
                  <button className='Holiday-trip-sign'>
                    <img src={Trip} alt='trip' />
                  </button>
                  <div className='Holiday-header'>Holiday</div>
                  <div className='holiday-Budget'>Budget</div>
                  <div className='holiday-Budget-amount'>80,000RS</div>
                </div>
                <div className='New-House'>
                  <button className='New-House-sign'>
                    <img src={House} alt='House' />
                  </button>
                  <div className='New-house-header'>New House</div>
                  <div className='New-house-Budget'>Budget</div>
                  <div className='New-house-Budget-amount'>80,000RS</div>
                </div>
                <div className='News-title'>
                  <div className='News-icon'>
                    <img src={news} alt='News' />
                  </div>
                  <div className='News-icon-Header'>
                    News-Title
                    <div className='News-details'>Lorem, ipsum.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar />
    </>
  );
};

export default UtilityFinance;
