import React from 'react'
import { useState } from 'react'
import { Navbar } from 'rsuite';
import widgetclose from '../assets/images/widgetclose.png'
import './BottomSetting.css'

const BottomSetting = ({title,content,keepbtn,resetbtn}) => {
    const [ closedialog2 , setClosedialog2] = useState(false);

    const Closedialog2 = () => {
        setClosedialog2(true);
    }
    return(
    <div className='modal-main'>
    { closedialog2 ? null : (
    <div className='power'>
      <div className='power_title'>{title}</div>
      <div className="closepower">
        <img src={widgetclose} alt='widgetclose' onClick={Closedialog2} draggable={false} />
      </div>
      <div className='power_content'>{content}</div>
      <button className='keep_btn'>{keepbtn}</button>
      <button className='pwr_reset_btn'>{resetbtn}</button>
    </div>
    )}
  </div>
);
};

export default BottomSetting



    // title : "Reset Settings?",
    // content:"After proceeding it will reset entire settings of the software including Theme and Audio settings. You will be logged out after you reset settings.",
    // keepbtn:"Keep original",
    // resetbtn:"Reset"

    // let {title, content, keepbtn, resetbtn} = props;