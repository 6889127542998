import React from 'react'
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";


Chart.register(ArcElement);

const data = {
  datasets: [
    {
      data: [30,3,6,60],
      backgroundColor: [
        '#9747FF','white','#9747FF','#36434E'
      ],
      display: true,
      borderColor: "#36434E"
    }
  ]
};


const DoughnutChart = () => {
  return (
    <div className='budget_container'>
    <div className='doughnut'>
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false
            }
          },
          rotation: -90,
          circumference: 180,
          cutout: "70%",
          maintainAspectRatio: true,
          responsive: true
        }}
      />
    </div>
      <div
        style={{
          width:'40%',
          // position: "absolute",
          top: "55%",
          left: "50%",
          // transform: "translate(-50%, -50%)",
          textAlign: "center"
        }}
        >
        <div className='value'>25.71%
        <p className='budget-content'>You have consumed 25 % of your monthly budget on medical chechups</p>
        </div>
      </div>
        </div>
  )
}

export default DoughnutChart
