import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../navbar/Navbar'
import './Account.css'

const Account = () => {
  return (
    <div className='account'>
        <ul className='accounts-list'>
            <div className='row1'>
            <Link to= '/Settings'><li className='account-items'>Settings</li></Link>
            <Link to='/Preferences'><li className='account-items'>Prefernces</li></Link>
            </div>
            <div className='row2'>
            <Link to='/Devices'><li className='account-items'>My Devices</li></Link>
            <Link to='/Billing'><li className='account-items'>My Billings</li></Link>
            </div>
        </ul>
        <Navbar/>

        
    </div>
  )
}

export default Account