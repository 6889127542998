import React from 'react'
import'./Preferencesoptions.css'

const GeneralPreferences = () => {
  return ( 
    <div className='general-settings'>
        <section className='left-general-settings'>
        <div className='content-input'>
        <div className='first-name'>First Name</div>
        <input type="text" className='textbox-b' placeholder='Enter Name'/>
        </div>

        <div className='content-input'>
        <div className='nickname'>Nickname</div>
        <input type="text" className='textbox-g' placeholder='Nickname'/>
        </div>

        <div className='content-input'>
        <div className='email-id'>Email Id</div>
        <input type="text" className='textbox-g' placeholder='example@gmail.com' />
        </div>

      

        <div className='content-input'>
        <div className='address'>Address</div>
        <div className='s-line1'><input type="text" className='textbox-b' placeholder='Enter Building name'/></div>
        <div className='s-line2'><input type="text" className='textbox-b' placeholder='Enter Locality or Nearby Landmark'/></div>
        <div className='s-line3'><input type="text" className='textbox-b' placeholder='Enter Pincode'/></div>
        </div>
        <div className='gender-title'>Gender</div>

        <div className='gender'>
            <div className='female'>
                <div className='gender-btn-item'>
                    <div className='gender-head'>
                        Female
                    </div>
                    <div className='gender-p'>
                        She/Her
                    </div>
                </div>
            </div>
            <div className='male'>
                <div className='gender-btn-item'>
                    <div className='gender-head'>
                        Male
                    </div>
                    <div className='gender-p'>
                        He/Him
                    </div>
                </div>
            </div>
            <div className='other'>
                <div className='gender-btn-item'>
                    <div className='gender-head'>
                        Others
                    </div>
                    <div className='gender-p'>
                        They/Them
                    </div>
                </div>
            </div>
        </div>
        


        </section>
        <section className='right-general-settings'>
        <div className='content-input'>
        <div className='last-name'>Last Name</div>
        <input type="text" className='textbox-b' placeholder='Enter Name'/>
        </div>

        <br/>

        <div className='content-input'>
        <div className='phoneno'>DOB</div>
        <input type="text" className='textbox-b' placeholder='DOB'/>
        </div>



        <div className='country-city'>
        <div className='content-input'>
        <div className='country'>Country</div>
        <input type="text" className='textbox-b' placeholder='Country' />
        </div>
        <div className='content-input'>
        <div className='City'>City</div>
        <input type="text" className='textbox-b' placeholder='City'/>
        </div>
        </div>

        <br/>
        
        <div className='content-input'>
        <div className='phoneno'>Phone No.</div>
        <input type="text" className='textbox-b' placeholder='778-701- 1234'/>
        </div>
        <div className='content-input'>
        <div className='occupation'>Occupation</div>
        <input type="text" className='textbox-b' placeholder='Occupation'/>
        </div>

        <div className='content-input'>
        <div className='bio'>Bio</div>
        <div className='bio-t'><input type="text" className='textbox-b' placeholder='Bio'/></div>
        </div>
        
        </section>
    </div>
  )
}

export default GeneralPreferences