import React from 'react'
import { Link, Outlet,NavLink } from 'react-router-dom'
import Navbar from "../navbar/Navbar";
import Backbutton from '../assets/images/left.png'
import {useRef,useEffect} from 'react'
import './Devices.css'
const Devices = () => {
  const navbar = useRef(null);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      const btnContainer = navbar.current;
      const btns = btnContainer.getElementsByClassName('btn');
  
      for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener('click', function () {
          const current = document.getElementsByClassName('active');
          current[0].className = current[0].className.replace(' active');
  
          this.className += ' active';
        });
      }
      const generalSettingsBtn = navbar.current.querySelector('.all-devices-btn');
      generalSettingsBtn.addEventListener('click', () => {
        generalSettingsBtn.className += ' active';
      });
    });
  }, []);  
  return (
    <div className='devices'>
       <div className='background'>
          <div className='purple'></div>
          <div className='blue'></div>
        </div>
            <div className='backbutton'> <NavLink to= '/account'> <img  src={Backbutton} alt="" /></NavLink></div>
      <div className='devices-background'>
        <div className='devices-content'>
          <div className='devicesnav' id='devicesnav' ref={navbar}>
            <ul className='devices-navbar'>
              <li className='devices-navbar-item'><NavLink className="btn active all-devices-btn" to='alldevices'><button className='devices-item'>All devices</button></NavLink></li>
              <li className='devices-navbar-item'><NavLink className="btn" to='devicessignin'><button className='devices-item'>SignIn Options</button></NavLink></li>
            </ul>
          </div>
          <Outlet />
        </div>
      </div>   
      <Navbar/>
    </div>
  )
}

export default Devices