import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import Backbutton from '../assets/images/left.png'
import Navbar from "../navbar/Navbar";
import './Billing.css'
import {useRef,useEffect} from 'react'

const Billing = () => {
  const navbar = useRef(null);

  useEffect(() => {
    window.requestAnimationFrame(() => {
      const btnContainer = navbar.current;
      const btns = btnContainer.getElementsByClassName('btn');
  
      for (let i = 0; i < btns.length; i++) {
        btns[i].addEventListener('click', function () {
          const current = document.getElementsByClassName('active');
          current[0].className = current[0].className.replace(' active');
  
          this.className += ' active';
        });
      }
      const generalSettingsBtn = navbar.current.querySelector('.mybillings-btn');
      generalSettingsBtn.addEventListener('click', () => {
        generalSettingsBtn.className += 'active';
      });
    });
  }, []); 
  return (
    <div className='billing'>
       <div className='background'>
          <div className='purple'></div>
          <div className='blue'></div>
        </div>
      <div className='backbutton'> <NavLink to= '/account'> <img  src={Backbutton} alt="" /></NavLink></div>
    <div className='billing-background'> 
      <div className='billing-content'> 
        <div className='billingNav' id='billingNav' ref={navbar}>
          <ul className='billing-navbar'>
            <li className='billing-navbar-item'><NavLink className="btn active" to='mybilling'><button className='billing-item'>My  billing</button></NavLink></li>
            
            <li className='billing-navbar-item'><NavLink className='btn' to='myplans'><button className='billing-item'>My Plans</button></NavLink></li>
          </ul>
        </div>
        <Outlet />
      </div>
    </div>
    <Navbar/>
  </div>
  )
}

export default Billing 