import React from "react";
import Navbar from "../navbar/Navbar";
import shopping from "../assets/images/shoppingi.png";
import storecard from "../assets/images/storecard.png";
import { BsSearch } from "react-icons/bs";
import Ratings2 from "../home/Ratings2";
import "./Store.css";
import store_BannerIMG from "../assets/images/Frame105.png";
const store = () => {
  return (
    <>
      <div className='stores'>
        {/* ======NavBar====== */}
        {/* Navbar CSS is in Home.css */}
        <div className='landingNavBar StoreAlignment'>
          <div className='RightNavBar'>
            {/* SearchBar */}
            <div className='SearchBar'>
              <div className='Inner_SearchArea'>
                <BsSearch className='SearchIcon' />
                <input type='text' className='inputText' placeholder='SEARCH' />
              </div>
            </div>
          </div>
          <div className='LeftNavBar'>
            {/* Notification */}
            <div className='Notification'></div>
            {/* Profile */}
            <div className='Profile'></div>
          </div>
        </div>
        <div className='background'>
          <div className='purple'></div>
          <div className='blue'></div>
        </div>
        <div className='storeContainer'>
          <div className='innerStoreContainer'>
            <div className='store_Banner'>
              <img src={store_BannerIMG}   alt='' />
              <div className='BannerText'>
                <h1>Exclusive Package</h1>
                <p>Details about Banner</p>
              </div>
            </div>
            {/* Latest release */}
            <div className='latestRelease'>
              <div className='HeadTitle'>
                <h1>Latest Release</h1>
                <h5>View More</h5>
              </div>
              <div className='CardType3_Horizontal_Alignment'>
                {/* ======= CARD ==== */}
                
                <div className='Card_BG_gradient'>
                  <div className='CardLatestRelease'>
                    <div className='LRCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='LRCardText'>
                      <div className='InnerLRCardText'>
                        <div className='TitleLRCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='LRCardDownload'>
                          <div className='TextLRCardDownload'>
                            <h3> 12K D</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_BG_gradient'>
                  <div className='CardLatestRelease'>
                    <div className='LRCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='LRCardText'>
                      <div className='InnerLRCardText'>
                        <div className='TitleLRCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='LRCardDownload'>
                          <div className='TextLRCardDownload'>
                            <h3> 12K D</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_BG_gradient'>
                  <div className='CardLatestRelease'>
                    <div className='LRCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='LRCardText'>
                      <div className='InnerLRCardText'>
                        <div className='TitleLRCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='LRCardDownload'>
                          <div className='TextLRCardDownload'>
                            <h3> 12K D</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_BG_gradient'>
                  <div className='CardLatestRelease'>
                    <div className='LRCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='LRCardText'>
                      <div className='InnerLRCardText'>
                        <div className='TitleLRCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='LRCardDownload'>
      
                            <h3> 12K D</h3>
                 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  



              </div>
            </div>

            {/* Recommended Package */}
            <div className='RecommendedPackage'>
              <div className='HeadTitle'>
                <h1>Recommended Package</h1>
                <h5>View More</h5>
              </div>
              <div className='CardType3_Horizontal_Alignment'>
                {/* card */}
                <div className='Card_Recommended_BG'>
                  <div className='CardRecommendedPackage'>
                    <div className='RecommendedPackageCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='RecommendedPackageCardText'>
                      <div className='InnerRecommendedPackageCardText'>
                        <div className='TitleRecommendedPackageCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='Text_RecommendedCard'>
                          <div className='RecommendedPackageCardDownload'>
                            Get Now
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_Recommended_BG'>
                  <div className='CardRecommendedPackage'>
                    <div className='RecommendedPackageCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='RecommendedPackageCardText'>
                      <div className='InnerRecommendedPackageCardText'>
                        <div className='TitleRecommendedPackageCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='Text_RecommendedCard'>
                          <div className='RecommendedPackageCardDownload'>
                            Get Now
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_Recommended_BG'>
                  <div className='CardRecommendedPackage'>
                    <div className='RecommendedPackageCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='RecommendedPackageCardText'>
                      <div className='InnerRecommendedPackageCardText'>
                        <div className='TitleRecommendedPackageCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='Text_RecommendedCard'>
                          <div className='RecommendedPackageCardDownload'>
                            Get Now
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className='Card_Recommended_BG'>
                  <div className='CardRecommendedPackage'>
                    <div className='RecommendedPackageCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='RecommendedPackageCardText'>
                      <div className='InnerRecommendedPackageCardText'>
                        <div className='TitleRecommendedPackageCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='Text_RecommendedCard'>
                          <div className='RecommendedPackageCardDownload'>
                            Get Now
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className='Card_Recommended_BG'>
                  <div className='CardRecommendedPackage'>
                    <div className='RecommendedPackageCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='RecommendedPackageCardText'>
                      <div className='InnerRecommendedPackageCardText'>
                        <div className='TitleRecommendedPackageCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='Text_RecommendedCard'>
                          <div className='RecommendedPackageCardDownload'>
                            Get Now
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className='Card_Recommended_BG'>
                  <div className='CardRecommendedPackage'>
                    <div className='RecommendedPackageCardImg'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='RecommendedPackageCardText'>
                      <div className='InnerRecommendedPackageCardText'>
                        <div className='TitleRecommendedPackageCardText'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='Text_RecommendedCard'>
                          <div className='RecommendedPackageCardDownload'>
                            Get Now
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* End */}
              </div>
            </div>


{/* ==========>>>>> TAGS  <<<<<=============== */}
<div className="Tag_mainContainer">

<div className='HeadTitle'>
                <h1>Recommended Package</h1>
                <h5>View More</h5>
              </div>

            <div className='tags_container'>
              {/* Give FIVE tags in upper_tags for maintain design */}
              <div className='upper_tags'>
                {/* Tags */}
                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>

                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>

                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>

                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>

                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>
                {/* end */}
              </div>

              <div className='bottom_tags'>
              {/* Tags */}
              <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>
                
                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>

                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>

                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>


                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>

                <div className='tags_Card'>
                  <div className='tags'>Text</div>
                </div>
              {/* end */}
              </div>
            </div>

            </div>

            {/* ULTIMATE PACK Voice Pack */}
            <div className='themePack UltimateVoicePack'>
              <div className='HeadTitle'>
                <h1>Ultimate Voice Pack</h1>
                <h5>View More</h5>
              </div>
              {/*  <= CARD =>   */}
              <div className='CardType3_Horizontal_Alignment'>
                {/* Cards */}
                <div className='Card_Theme_BG'>
                  <div className='CardType3'>
                    <div className='CardType3_IMG'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='CardType3_TextArea '>
                      <div className='CardType3_Inner_TextArea'>
                        <div className='CardType3_H35'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='CardType3_TotalDownlaods'>
                          Total Downloads 12.15k
                        </div>
                        <div className='CardType3_GetNow'>Get Now</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_Theme_BG'>
                  <div className='CardType3'>
                    <div className='CardType3_IMG'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='CardType3_TextArea '>
                      <div className='CardType3_Inner_TextArea'>
                        <div className='CardType3_H35'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='CardType3_TotalDownlaods'>
                          Total Downloads 12.15k
                        </div>
                        <div className='CardType3_GetNow'>Get Now</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_Theme_BG'>
                  <div className='CardType3'>
                    <div className='CardType3_IMG'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='CardType3_TextArea '>
                      <div className='CardType3_Inner_TextArea'>
                        <div className='CardType3_H35'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='CardType3_TotalDownlaods'>
                          Total Downloads 12.15k
                        </div>
                        <div className='CardType3_GetNow'>Get Now</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_Theme_BG'>
                  <div className='CardType3'>
                    <div className='CardType3_IMG'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='CardType3_TextArea '>
                      <div className='CardType3_Inner_TextArea'>
                        <div className='CardType3_H35'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='CardType3_TotalDownlaods'>
                          Total Downloads 12.15k
                        </div>
                        <div className='CardType3_GetNow'>Get Now</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* End */}
              </div>
            </div>
            {/*======================= THEME PACK ============== */}
            <div className='UltimateVoicePack'>
              <div className='HeadTitle'>
                <h1>Theme Pack Voice Pack</h1>
                <h5>View More</h5>
              </div>
              {/*  <= CARD =>   */}

              <div className='CardType3_Horizontal_Alignment'>
                <div className='Card_Theme_BG'>
                  <div className='CardType3'>
                    <div className='CardType3_IMG'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='CardType3_TextArea '>
                      <div className='CardType3_Inner_TextArea'>
                        <div className='CardType3_H35'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='CardType3_TotalDownlaods'>
                          Total Downloads 12.15k
                        </div>
                        <div className='CardType3_GetNow'>Get Now</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_Theme_BG'>
                  <div className='CardType3'>
                    <div className='CardType3_IMG'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='CardType3_TextArea '>
                      <div className='CardType3_Inner_TextArea'>
                        <div className='CardType3_H35'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='CardType3_TotalDownlaods'>
                          Total Downloads 12.15k
                        </div>
                        <div className='CardType3_GetNow'>Get Now</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_Theme_BG'>
                  <div className='CardType3'>
                    <div className='CardType3_IMG'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='CardType3_TextArea '>
                      <div className='CardType3_Inner_TextArea'>
                        <div className='CardType3_H35'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='CardType3_TotalDownlaods'>
                          Total Downloads 12.15k
                        </div>
                        <div className='CardType3_GetNow'>Get Now</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='Card_Theme_BG'>
                  <div className='CardType3'>
                    <div className='CardType3_IMG'>
                      <img src={store_BannerIMG} alt='' />
                    </div>
                    <div className='CardType3_TextArea '>
                      <div className='CardType3_Inner_TextArea'>
                        <div className='CardType3_H35'>
                          <h3>XOP GOSSSIP PLAIN</h3>
                          <h5>FREE</h5>
                        </div>
                        <div className='CardType3_TotalDownlaods'>
                          Total Downloads 12.15k
                        </div>
                        <div className='CardType3_GetNow'>Get Now</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* End */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="Cart">
            <div className='cartimg'><img src={shopping} alt="" /></div>
            </div>
        <div className="Banner"></div>
        <div className="storeshop">
            <div className="storeHead">Store</div>
                <div className="storeModal">
                    <div className="storeModal1">
                        <img src={storecard} alt='storecard'></img>
                            <div className="storeHead1-title">Title 
                            <p>Lorem ipsum dolor sit..</p>
                            </div>
                            <div className="hoveringStore-content">
                                    <div className="hovering-content1">
                                    <div className='storehead-1-prize'>prize:RS1000</div>
                                    <div className='storehead-1-Rating'> <Ratings2/></div>
                                    </div>
                                    <div className="hoverStore-button">
                                    <button className='storehead-1-buy'>Get It</button>
                                    </div>
                                    </div>
                    </div>
                    <div className="storeModal2">
                            <img src={storecard} alt='storecard'></img>
                                <div className="storeHead2-title">Title 
                                <p>Lorem ipsum dolor sit..</p>
                                </div>
                                <div className="hoveringStore-content">
                                    <div className="hovering-content1">
                                    <div className='storehead-2-prize'>prize:RS1000</div>
                                    <div className='storehead-2-Rating'> <Ratings2/></div>
                                    </div>
                                    <div className="hoverStore-button">
                                    <button className='storehead-2-buy'>Get It</button>
                                    </div>
                                    </div>
                    </div>
                    <div className="storeModal3">
                            <img src={storecard} alt='storecard'></img>
                                <div className="storeHead3-title">Title 
                                <p>Lorem ipsum dolor sit..</p>
                                </div>
                                <div className="hoveringStore-content">
                                    <div className="hovering-content1">
                                    <div className='storehead-3-prize'>prize:RS1000</div>
                                    <div className='storehead-3-Rating'> <Ratings2/></div>
                                    </div>
                                    <div className="hoverStore-button">
                                    <button className='storehead-3-buy'>Get It</button>
                                    </div>
                                    </div>
                    </div>
                    <div className="storeModal4">
                            <img src={storecard} alt='storecard'></img>
                                <div className="storeHead4-title">Title 
                                <p>Lorem ipsum dolor sit..</p>
                            </div>
                            <div className="hoveringStore-content">
                                    <div className="hovering-content1">
                                    <div className='storehead-4-prize'>prize:RS1000</div>
                                    <div className='storehead-4-Rating'> <Ratings2/></div>
                                    </div>
                                    <div className="hoverStore-button">
                                    <button className='storehead-4-buy'>Get It</button>
                                    </div>
                                    </div>
                    </div>
                </div>
        </div> */}
      </div>
      <Navbar />
    </>
  );
};

export default store;
