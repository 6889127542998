import React from 'react'
import './Settingsoptions.css'

const PaymentInfo = () => {
    return (
        <div className='payment-info'>
            <div className='content-title'>
                Plan
            </div>
            <div className='plan'>
                <div className='plan1'>
                    <div className='basic'>
                        <div className='plan-title'>
                            Basic
                        </div>
                        <div className='plan-amt'>
                            <span className='plan-bold'>
                                $/
                            </span>Month
                        </div>
                    </div>
                    <div className='plan-description'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                    <button className='plan_button'>Cancel Subscription</button>
                </div>
                <div className='plan2'>
                    <div className='basic'>
                        <div className='plan-title'>
                            Premium
                        </div>
                        <div className='plan-amt'>
                            <span className='plan-bold'>
                                $/ 
                            </span>Month
                        </div>
                    </div>
                    <div className='plan-description'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </div>
                    <button className='plan_button'>Upgrade</button>
              
                </div>
            </div>
            <div className='renew'>
                <div className='content-title'>Enable Auto Renew</div>
                <div className='renew-des'>We will renew your Product Subscription if the current plan expires</div>
            </div>

            <div className='payment-method'>
                <ul className='payment-method-list'>
                    <li className='payment-method-item'>Credit Card</li>
                    <li className='payment-method-item'>Debit Card</li>
                    <li className='payment-method-item'>UPIs</li>
                    <li className='payment-method-item'>All</li>
                </ul>
            </div>

            <div className='payment-card'>
                <div className='card1'>
                    <div className='card-type'>VisaCard</div>
                    <div className='card-img-num'>
                    <div className='card-img'></div>
                    <div className='card-num'>**** **** **** 0567</div>
                    </div>

                
                </div>
                <div className='card2'>
                    <div className='card-type'>VisaCard</div>
                    <div className='card-img-num'>
                    <div className='card-img'></div>
                    <div className='card-num'>**** **** **** 0567</div>
                    </div>

                
                </div>
            </div>
        </div>

    )
}

export default PaymentInfo