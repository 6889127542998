import React from 'react'
import './Devicesoptions.css'
import desktop from '../assets/images/desktop.png'
const AllDevices = () => {
    return (
        <div className='all-devices'>
            <div className='devices-left'>
                <div className='devices-method'>
                    <ul className='devices-method-list'>
                        <li className='devices-method-item'>Online</li>
                        <li className='devices-method-item'>Offline</li>
                        <li className='devices-method-item'>Blocked</li>
                        <li className='devices-method-item'>All</li>
                    </ul>
                </div>
                <div className='active-users'>
                    <div className='desktop-user'>
                        <img src={desktop} alt='desktop'/>
                    </div>
                    <div className='desktop-user'>
                        <img src={desktop} alt='desktop'/>
                    </div>
                </div>
            </div>
            <div className='devices-right'>
                <div className='devices-method'>
                    <ul className='devices-method-list'>
                        <li className='devices-method-item'>Statistics</li>
                        <li className='devices-method-item'>Control</li>
                    </ul>
                </div>
                <div className='device-statistics'>
                    <div className='content-title'>
                    Daily Usage on Device
                    </div>
                    <div className='content-subtitle'>
                    Statistics subinformation
                    </div>
                    <div className='statistics-graph'>
                        <div className='graph-line1'>23</div>
                        <div className='graph-line2'>36</div>
                        <div className='graph-line3'>142</div>
                        <div className='graph-line2'>34</div>
                        <div className='graph-line3'>45</div>
                        <div className='graph-line1'>85</div>
                        <div className='graph-line2'>64</div>
                    </div>
                </div>
                <div className='battery-percentage'>
                    <div className='content-title'>
                    Battery Percentage
                    </div>
                    <div className='battery-percentage-graph'>
                    <div className='battery-graph'>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                    </div>
                    72%
                    </div>
                </div>


                <div className='cpu-temp'>
                    <div className='content-title'>
                    CPU Temprature
                    </div>
                    <div className='cpu-percentage-graph'>
                    <div className='cpu-graph'>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-linec'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                        <div className='battery-line'></div>
                    </div>
                    20%
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllDevices