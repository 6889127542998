import React, { useState } from "react";
import "./UtilityHealthcare.css";
import LineChart from "./LineChart";
import Vector1 from "./icons/Vector1.png";
import Vector5 from "./icons/Vector5.png";
import Vector7 from "./icons/Vector7.png";
import Vector2 from "./icons/Vector2.png";
import Vector6 from "./icons/Vector6.png";
import Vector3 from "./icons/Vector3.png";
import Vector4 from "./icons/Vector4.png";
import { FaAngleUp } from "react-icons/fa";
import Backbutton from "../assets/images/left.png";
import { NavLink } from "react-router-dom";
import AppointmentMonths from "./AppointmentMonths";
import DoughnutChart from "./DoughnutChart";
import Navbar from "../navbar/Navbar";

const UtilityHealthcare = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthIndex = new Date().getMonth();
  let monthName = monthNames[monthIndex];

  const [checkup, setCheckup] = useState(true);
  const showDropdown = () => {
    setCheckup(true);
  };
  const hideDropdown = () => {
    setCheckup(false);
  };

  const [illness, setIllness] = useState(true);

  // const showIllnessDropdown = () => {
  //   setIllness(true);
  // };
  const hideIllnessDropdown = () => {
    setIllness(false);
  };

  const [medicines, setMedicines] = useState(true);
  const showMedicinesDropdown = () => {
    setMedicines(true);
  };
  const hideMedicinesDropdown = () => {
    setMedicines(false);
  };

  return (
    <div className='utilityhealthcare'>
      <div className='background'>
        <div className='purple'></div>
        <div className='blue'></div>
      </div>
      <span className='backbutton1'>
        <NavLink to='/utility'>
          <img src={Backbutton} alt='' />
        </NavLink>
      </span>
      <div className='utilityhealthcare-backround'>
        
        <div className='healthcare-left'>
          <div className="health_container">

          
          <div className='health-info' onMouseEnter={showDropdown}>
            Last checkup
            {/* <button className='lastcheckup-button' onClick={hideDropdown}>
              <FaAngleUp />
            </button> */}
          </div>

          {checkup ? (
            <div>
              <div className='Health-content'>
                <ul>
                  <li>Blood Checkup</li> <li >07.10.22</li>
                </ul>
              </div>
              <div className='Health-content'>
                <ul>
                <li> Blood Sugar</li>  <li>06.8.22 </li>
                </ul>
              </div>
            </div>
          ) : null}

          <div className='health-info' onClick={()=>setIllness(false)} >
            Previous Illness
              
            {/* <button className='illness-button' >
              <FaAngleUp />
            </button> */}
          </div>
          {illness ? (
            <div>
              <div className='Health-content Ctx'>
                <ul>
                  <li>Fever</li>  <li> 1 month ago</li>
                </ul>
              </div>
              <div className='Health-content Ctx'>
                <ul>
                <li> Infection</li>
                  <li>2 months ago</li>
                </ul>
              </div>
            </div>
          ) : null}

          <div className='health-info' onMouseEnter={showMedicinesDropdown}>
            Medicines
            <button
              className='medicines-button'
              onClick={hideMedicinesDropdown}
            >
              {/* <FaAngleUp /> */}
            </button>
          </div>
          {medicines ? (
            <div>
              <div className='Health-content'>
                <ul>
                  <li>Lorem Ipsum</li> <li> Lorem Ipsum</li>
                </ul>
              </div>
              <div className='Health-content'>
                <ul>
                  <li>Lorem Ipsum</li> <li> Lorem Ipsum</li>
                </ul>
              </div>
            </div>
          ) : null}
          </div>
        </div>

        {/* Health Display */}

        <div className='xxx helth'>
          <div className='health-display'>
            <div className='inner_health_display'>
              <div className='health-display1'>
                <div className='header_HealthDisplay1'>
                  <div> 
                    <img className='icon5' src={Vector5} alt='' />
                    <img className='icon1' src={Vector1} alt='' />{" "}
                    <img className='icon7' src={Vector7} alt='' />{" "}
                  </div>

                  <span className='bloodpressure'>Blood Pressure</span>
                </div>
                <div className='Reading_healthDisplay'>
                  <h3 className='normal1'>Normal</h3>{" "}
                  <div className='bp'>
                    119/80 <span className='measureunit1'>mgHg</span>
                  </div>
                </div>
              </div>
              <div className='health-display1'>
                <div className='header_HealthDisplay1'>
                  <div>
                    <img className='icon2' src={Vector2} alt='' />{" "}
                    <img className='icon6' src={Vector6} alt='' />{" "}
                  </div>
                  <span className='heartrate'>Heart Rate</span>{" "}
                </div>
                <div className='Reading_healthDisplay'>
                  <h3 className='checkupnow'> Checkup Now</h3>
                  <div className='hr'>
                    105<span className='measureunit2'>bpm</span>
                  </div>
                </div>
              </div>
            </div>
            <div className='inner_health_display'>
              <div className='health-display1'>
                <div className='header_HealthDisplay1'>
                  <div>
                    <img className='icon3' src={Vector3} alt='' />
                  </div>
                  <span className='stepscovered'>Steps Covered</span>
                </div>
                <div className='Reading_healthDisplay'>
                  <h3 className='good'>Good</h3>
                  <div className='sc'>
                    8000 <span className='measureunit3'>Steps</span>
                  </div>
                </div>
              </div>
              <div className='health-display1'>
                <div className='header_HealthDisplay1'>
                  <div>
                    <img className='icon4' src={Vector4} alt='' />
                    <img className='icon-second' src={Vector4} alt='' />{" "}
                  </div>
                  <span className='water'>Water</span>{" "}
                </div>
                <div className='Reading_healthDisplay'>
                  <h3 className='normal2'>Normal</h3>
                  <div className='wtr'>
                    3.5<span className='measureunit4'>Litres</span>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>

          <div className='exercise_container'>
            <div className='exercise'>
              <div className='exercise_Header'>
                <h1>Exercise</h1>
                <div className='exercise_content'>
                  <button className='gym'>Gym</button>
                  <button className='yoga'>Yoga</button>
                </div>
              </div>
                <div className="main_exercise">

                  <div className='empty'></div>
                <div className="flex_step">

                  <div className='step'>
                    25
                    <span className='lunges'>Lunges</span>
                    <input className='checkbox1' type='checkbox' />
                  </div>

                  <div className='step'>
                    25
                    <span className='lunges'>Lunges</span>
                    <input className='checkbox1' type='checkbox' />
                  </div>


                  <div className='step'>
                    25
                    <span className='lunges'>Lunges</span>
                    <input className='checkbox1' type='checkbox' />
                  </div>
                  
                  

               
                </div>
             
                </div>
                {/* <div className='step2'>
                  25 <span className='burpees'>Burpees</span>{" "}
                  <input className='checkbox2' type='checkbox' />
                </div>  
                <div className='step3'>
                  30 <span className='bicepcurls'>Bicep curls</span>
                </div>
                <div className='step4'>
                  50 <span className='squats'> Squats</span>
                </div> */}
            </div>
          </div>

          <div className='budgeting'>
            Budgeting
            <div>
              <DoughnutChart />
            </div>
          </div>

          {/* <div className='checkup1'>
            <h3>Next Appointment for blood checkup on 12.12.22 </h3>
            <p>Appointment scheduled with Apollo Clinic - Pune</p>
          </div>
          <div className='checkup2'>
            <h3>Note : Check your sugar levels</h3>
            <p>Last checked 2 months ago.</p>
          </div>
          <div className='checkup3'>
            <h3>Next Appointment for blood checkup on 12.12.22</h3>
            <p>Appointment scheduled with Apollo Clinic - Pune</p>
          </div> */}
        </div>

        <div className='xxx'>
          {/* Line Chart */}
          <div className='lineChart_container'>
            <LineChart />
          </div>
          <div className='appointment'>
            <div className='left_part_appointment'>
              <h2>Upcoming Appointments</h2>
              <section className='month'>
                <div>
                  <select className='options'>
                    <option value='' className='monthlist' selected>
                      {monthName}
                    </option>

                    <option>January</option>
                    <option>February</option>
                    <option>March</option>
                    <option>April</option>
                    <option>May</option>
                    <option>June</option>
                    <option>July</option>
                    <option>August</option>
                    <option>September</option>
                    <option>October</option>
                    <option>November</option>
                    <option>December</option>
                  </select>
                </div>
              </section>
              <AppointmentMonths />
            </div>
            <div className='popup'>
              <div className='popup-content'>
                <h1>Book Appointments</h1>
                <p className='popupabout'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
              <div>
                <button className='popup-button'>Block</button>
              </div>
            </div>
          </div>

          {/*  */}
          <div className='improvement_container'>
            <div className='improvement'>
              <ul className='list'>
                <li className='list'>My health is improving everyday</li>
                <li className='list'>I live a healthy lifestyle</li>
                <li className='list'>My health is my priority</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default UtilityHealthcare;
