
import {FaStar} from 'react-icons/fa'

const Rating = ({rating}) => {
const stars = Array(5).fill(0);

  return (
    <div className='rating' style={styles.container}>
      <div style={styles.stars}> {stars.map((_, index) => {
        return (
          <FaStar
          size={17}
          style={{
            marginRight: 5,
            color:'#00D7FB'
          }}
          />
        )
      })} </div>
    </div>

    
  )
}

const styles ={
  container: {
    display: 'flex',
    flexDirection:'column',
    alignItem:'start'
  }
}

export default Rating

