import React from 'react'
import './Myplan.css'
import MyPlansCard from './MyPlansCard'




const MyPlans = () => {
 
  return (
<div className="myplans">
<div className="myplans-content">
<div className="myplans-about"><div className='about'> <strong className='h1'>Flexible</strong> PLans</div>
<h4 className='h4'>Choose a plan that works best for you and your team</h4>
</div>
<div className='card-div'>

<MyPlansCard/>
</div>
</div>
</div>

  )
}

export default MyPlans