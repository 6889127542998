import React from 'react'
import userimg from '../assets/images/userimg.png'
import './Settingsoptions.css'
const CreateAccount = () => {
  return ( 
    <div className='create-account'>
      <div className='create-account-left'>
        <div className='content-title'>Connected Accounts</div>
        <table className='created-accounts'>
          <tr>
            <th className='table-heading'>Name</th>
            <th className='table-heading'>Session Info</th>
            <th className='table-heading'>Status</th>
          </tr>

          <tr className='table-row'>
            <td className='table-user'><img src={userimg}></img>Pranshu Rajan</td>
            <td className='table-session-info'>Last login at 12:00 Am</td>
            <td className='table-status'>online</td>
          </tr>

          <tr className='table-row'>
            <td className='table-user'><img src={userimg}></img>Pranshu Rajan</td>
            <td className='table-session-info'>Last login at 12:00 Am</td>
            <td className='table-status'>online</td>
          </tr>

        </table>
      </div>
      <div className='create-account-right'>
        <div className='invite-friends'>
          <div className='friends-bg'>
            <div className='invite-your-friends'>
              Invite Your Friends
            </div>
            <div className='friends-description'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit,
            </div>

          </div>
        </div>
        <div className='content-title'>Send Invite</div>
        <div className='content-subtitle'>Email id</div>
        <div className='input-email-id'>
        <input type="text" className='textbox-b' placeholder='Send Invite via email' />
        </div>

        <div className='line-or'>
          <div className='line'></div>
          <div className='or'>OR</div>
          <div className='line'></div>
        </div>

        <div className='content-subtitle'>Nickname</div>
        <div className='input-email-id'>
        <input type="text" className='textbox-b' placeholder='Send Invite via nickname' />
        </div>

        <div className='content-subtitle'>Send Custom Invite</div>
        <div className='input-email-id'>
        <input type="text" className='textbox-b' placeholder='Send Invite via nickname' />
        </div>
      </div>
    </div>
  )
}

export default CreateAccount