import React from 'react'
import {Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
const LineChart = () => {
    const data = {
        labels: ['April','May','June','july','Aug',],
        datasets: [
            {
            label: 'Account-1',
            data: [50,20,40,60,34],
              borderColor: '#00D7FB',
              backgroundColor: '#00D7FB',
                
            },
        ]
      };
      const options = {
        responsive: true,
        plugins: {
          legend: {
                display: null
              
          },
        },
      };
  return (
    <div>
      <div className="linechart">
        <Line
            data={data}
            options={options}
            
        />
    </div>
    </div>
  )
}

export default LineChart
