import React from 'react'
import Navbar from '../navbar/Navbar';
import Home from '../home/Home';
const Dashboard = () => {
  return (
    <>
    <Home/>
    <Navbar/>
    </>
  )
}

export default Dashboard
