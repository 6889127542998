import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from "../navbar/Navbar";
import './Utility.css'

const Utility = () => {
  return (
    <div className='utility'>
        <ul className='utilitys-list'>
            <div className='row1'>
            <Link to= '/Education'><li className='utility-items'>Education</li></Link>
            <Link to='/Dailyuse'><li className='utility-items'>Daily Use</li></Link>
            </div>
            <div className='row2'>
            <Link to='/Finance'><li className='utility-items'>Finance</li></Link>
            <Link to='/Healthcare'><li className='utility-items'>Healthcare</li></Link>
            </div>
            <div className='row3'>
            <Link to='/Computing'><li className='utility-items'>Computing</li></Link>
            <Link to='/Influencer'><li className='utility-items'>Influencer</li></Link>
            </div>
        </ul>
        <Navbar/>
    </div>
  )
}

export default Utility